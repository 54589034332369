<template>
  <div>
    <label v-if="label" :for="id" class="label-white">{{ label }}</label>
    <div class="custom-select">
      <select
        :id="id"
        class="select-input"
        @change="selectValue"
        :data-search="dataSearch"
      >
        <option v-if="noAll" value="" :selected="selectedValue == ''">
          {{ defaultSelect }}
        </option>
        <option v-else value="">Todas</option>
        <option
          v-for="(value, index) in values"
          :key="index"
          :value="value"
          :selected="value == selectedValue"
        >
          {{ value }}
        </option>
      </select>
      <DropDownArrowIcon :isButton="true" class="icon-absolute noselect" />
    </div>
  </div>
</template>

<script>
import DropDownArrowIcon from "@/assets/icons/DropDownArrowIcon.vue";

export default {
  name: "SelectCustom",
  props: {
    id: { type: String, default: "" },
    label: { type: String, default: "" },
    selectedValue: { type: String, default: "" },
    defaultSelect: { type: String, default: "" },
    dataSearch: { type: String, default: "" },
    values: { type: Array },
    noAll: { type: Boolean, default: false },
  },
  components: {
    DropDownArrowIcon,
  },
  methods: {
    selectValue(event) {
      this.$emit("selectValue", {
        id: event.target.id,
        value: event.target.value,
        target: event.target,
      });
    },
  },
};
</script>

<style lang="scss" scoped>
@import "/Inputs";
</style>
